/* eslint-disable @typescript-eslint/camelcase */
import { Auth, Logger } from "aws-amplify"
import { FormikHelpers } from "formik"
import { navigate } from "gatsby"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGlobalStyles } from "../../styles/globalStyles"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { useNotify } from "../../notifications"
import { toInternationalPhoneNumber } from "../../utils/phonenumber"
import FormContainer from "./FormContainer"
import { Wrapper, PageContainer, LogoContainer } from "./SignUpPage"
import SignInForm, { FormValues } from "./SignInForm"
import { Logo, SEO } from ".."
import "./Auth.css"
import { RootState } from "../../state/rootReducer"
import { useSelector } from "react-redux"
import styled from "styled-components"
import CompactFooter from "../CompactFooter"
import ConfirmSignInForm, {
  FormValues as confirmFormValues,
} from "./ConfirmSignInForm"
import { isMobile } from "react-device-detect"

const SignInContainer = styled.div`
  padding: 1rem 0;
  width: 700px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  @media (max-width: 960px) {
    padding: 120px 50px 0px 50px;
  }

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

const SignUpContainer = styled(Grid)`
  text-align: center;
`

const DescriptionTypography = styled(Typography)`
  word-break: word;
  text-align: center;
`

const ForgotPasswordContainer = styled.div`
  text-align: center;
  padding: 10px;
  justify-content: center;
`

const SignInPage: React.FC<{}> = () => {
  const productName = process.env.PRODUCT_NAME || "paycheck"
  const globalStyles = useGlobalStyles()
  const logger = useMemo(() => new Logger(SignInPage.name), [])
  const [loading, setLoading] = useState(true)
  const notify = useNotify()
  const [user, setUser] = useState({})
  const [showConfirm, setShowConfirm] = useState(false)

  const notifications: any = useSelector<RootState>(
    (state) => state.notifications,
  )
  const checkAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      // Got authenticated user
      navigate("/app", { replace: true })
      notify.info(`Welcome back ${user.attributes.given_name}.`)
    } catch (err) {
      // No authenticated user
      setLoading(false)
    }
  }, [notify])

  useEffect(() => {
    checkAuthenticatedUser()
  }, [checkAuthenticatedUser])

  const handleConfirmSubmit = async (
    values: confirmFormValues,
    actions: FormikHelpers<confirmFormValues>,
  ): Promise<void> => {
    const { code } = values
    try {
      const loggedUser = await Auth.confirmSignIn(user, code)
      logger.info("SignIn successfull.", loggedUser)
      notify.success("SignIn confirmed.")
      navigate("/app", { replace: true })
    } catch (err) {
      logger.error(err)
      notify.error(err.message)
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const { phone_number, password } = values
    const phoneNumber = toInternationalPhoneNumber(phone_number || "")
    try {
      const user = await Auth.signIn(phoneNumber || "", password)
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setUser(user)
        setShowConfirm(true)
      } else {
        logger.info(user)
        notify.success("You signed in successfully.")

        setTimeout(() => {
          navigate("/app", { replace: true })
        }, 500)
      }
    } catch (err) {
      logger.error(err)
      if (err.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(phoneNumber || "")
        navigate("/confirm-sign-up", {
          state: { phoneNumber, password },
        })
      } else {
        notify.error(err.message)
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  if (loading) return null
  return (
    <PageContainer>
      <LogoContainer>
        <Logo height={280} />
      </LogoContainer>
      <Wrapper>
        <SignInContainer>
          <Container component="main">
            <SEO title="Sign in" />
            <FormContainer>
              <DescriptionTypography
                variant="h4"
                className={globalStyles.fontSemiBold}
              >
                Welcome to{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {productName}
                </span>
              </DescriptionTypography>
              <DescriptionTypography
                variant="subtitle1"
                className={globalStyles.textLightGrey}
              >
                Please enter your credentials to access your account
              </DescriptionTypography>
              {notifications?.notification?.variant === "error" && (
                <div className="erroText">
                  {notifications?.notification?.message}
                </div>
              )}

              {notifications?.notification?.variant === "success" && (
                <div className="successText">
                  {notifications?.notification?.message}
                </div>
              )}
              <Box
                component="div"
                mt={5}
                mx={"auto"}
                style={{ width: isMobile ? "100%" : "60%" }}
              >
                {showConfirm ? (
                  <ConfirmSignInForm onSubmit={handleConfirmSubmit} />
                ) : (
                  <SignInForm onSubmit={handleSubmit} />
                )}
                <ForgotPasswordContainer>
                  <Link
                    href="/forgot-password"
                    color="secondary"
                    className={globalStyles.fontSemiBold}
                  >
                    {"Forgot Password"}
                  </Link>
                </ForgotPasswordContainer>

                <SignUpContainer container justify="center">
                  <Grid item xs={12} sm={5}>
                    <div className={globalStyles.textLightGrey}>
                      Don&apos;t have an account ?
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Link
                      href="/sign-up"
                      color="secondary"
                      className={globalStyles.fontSemiBold}
                    >
                      {"Register"}
                    </Link>
                  </Grid>
                </SignUpContainer>
              </Box>
            </FormContainer>
          </Container>
        </SignInContainer>
      </Wrapper>
      <CompactFooter />
    </PageContainer>
  )
}

export default SignInPage
