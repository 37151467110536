import defaultTheme from "../defaultTheme";
import paywellTheme from "../paywellTheme";
import eshandiTheme from "../eshandiTheme";

const themeSelector = (companyName: string) => {
  switch (companyName) {
    case "paywell":
      return paywellTheme;
    case "eshandi":
      return eshandiTheme;
    default:
      return defaultTheme;
  }
};

export default themeSelector;eshandiTheme